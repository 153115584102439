import React from 'react';
import { makeSubplots } from 'plotly.js/dist/plotly-cartesian';

const titles = [
  {
    name: 'Betweenness',
    x: 0,
    y: 1.05,
  },
  {
    name: 'Closeness',
    x: 0.375,
    y: 1.05,
  },
  {
    name: 'Degree',
    x: 0.625,
    y: 1.05,
  },
  {
    name: 'Expected Influence',
    x: 1,
    y: 1.05,
  },
];

export const plotLayout = (windowWidth, windowHeight, sidebarOpen) => {
  let width = windowWidth - 240;
  if (sidebarOpen) {
    width -= 342;
  }
  return {
    showlegend: false,
    grid: {
      rows: 1,
      columns: 4,
      subplots: [['xy', 'x2y', 'x3y', 'x4y']],
    },
    hovermode: 'y unified',
    responsive: true,
    useResizeHandler: true,
    autosize: true,
    margin: {
      l: 160,
      r: 5,
      t: 25,
      b: 20,
      pad: 0,
      autoexpand: true,
    },
    width: width,
    height: windowHeight - 190,
    annotations: titles.map((title) => {
      return {
        xref: 'paper',
        yref: 'paper',
        x: title.x,
        y: title.y,
        text: title.name,
        showarrow: false,
        font: {
          size: 16,
          color: 'black',
        },
      };
    }),
  };
};

export const plotCentrality = (insightsData) => {
  const { btwn, clsn, degr, eign, verts } = insightsData.centrality;

  const btwnPlot = {
    x: btwn,
    name: 'Betweenness',
    color: 'saddlebrown',
  };
  const clsnPlot = {
    x: clsn,
    name: 'Closeness',
    color: '#276445',
    xaxis: 'x2',
  };
  const degrPlot = {
    x: degr,
    name: 'Degree',
    color: '#FEA71C',
    xaxis: 'x3',
  };
  const eignPlot = {
    x: eign,
    name: 'Expected Influence',
    color: '#BC5939',
    xaxis: 'x4',
  };

  const figures = [btwnPlot, clsnPlot, degrPlot, eignPlot];

  const centralityFigures = figures.map((fig) => {
    const { color, ...figureData } = fig;
    return {
      ...figureData,
      y: verts,
      type: 'scatter',
      mode: 'lines+markers',
      marker: {
        symbol: 'circle',
        size: 5,
        color,
      },
    };
  });

  return centralityFigures;
};

import { API, Auth, graphqlOperation } from 'aws-amplify';
import { apiQuery, apiBatchQuery } from './base';

export const listMeals = /* GraphQL */ `
  query ListMeals($filter: ModelMealFilterInput, $limit: Int, $nextToken: String) {
    listMeals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dateAndTimeSubmitted
        mealTime
        appUserID
        name
        foods {
          fdcid
          count
          portion
          portionID
          name
        }
      }
      nextToken
    }
  }
`;

const fetchFoods = async (foods) => {
  try {
    const apiName = 'fdcAPI';
    const path = '/fdc';
    const myInit = {
      body: { foods },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    };

    const response = await API.post(apiName, path, myInit);
    console.log(response);
    return response.foods;
  } catch (error) {
    console.error(`Error fetching data: ${error.message}`);
    throw error;
  }
};

const mapMeals = (logs, fdcData, appUsers, resolver) => {
  const meals = [];
  logs.forEach((log) => {
    const { appUserID, id, foods, ...logNoID } = log;
    const appUser = appUsers.find((user) => user.id === appUserID);
    if (appUser) {
      const { username } = appUser;
      const fdcFoods = foods.map((food) => {
        return fdcData.find((i) => {
          return i.fdcid === food.fdcid;
        });
      });
      const fullLog = {
        username: username,
        foods: fdcFoods,
        ...logNoID,
      };
      meals.push(fullLog);
    }
  });
  resolver(meals);
};

function splitArray(inputArray, chunkSize) {
  const result = [];
  for (let i = 0; i < inputArray.length; i += chunkSize) {
    result.push(inputArray.slice(i, i + chunkSize));
  }
  return result;
}

export const getNutritionLogsForUsers = async (appUsers, setNutritionLogs) => {
  const appUserFilters = splitArray(
    appUsers.map((user) => {
      return { appUserID: { eq: user.id } };
    }),
    50
  );
  let nutritionLogs = [];
  let foods = [];

  // grab logs
  try {
    await Promise.all(
      appUserFilters.map(async (filters) => {
        await apiBatchQuery(listMeals, { filter: { or: filters } }, 'listMeals', (data) => {
          data.forEach((log) => {
            nutritionLogs.push(log);
            log.foods?.forEach((food) => {
              foods.push(food);
            });
          });
        });
      })
    );
    if (foods.length) {
      // fetch from FDC API
      fetchFoods(foods)
        .then((foodsData) => {
          mapMeals(nutritionLogs, foodsData, appUsers, setNutritionLogs);
        })
        .catch((error) => console.error(`Error: ${error.message}`));
    }
  } catch (e) {
    console.log(e);
  }
};

export const getNutritionLogsForUser = (appUserID, setNutritionLogs) => {
  return apiQuery(listMeals, { filter: { appUserID: { eq: appUserID } } }, setNutritionLogs);
};
